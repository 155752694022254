var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('reDelivery.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            {
                text: _vm.$t('reDelivery.pageTitle'),
                active: true
            }
        ]}}),_c('ts-panel',[_c('ts-panel-wrapper',[_c('div',{staticClass:"tw-flex tw-items-center",class:_vm.$whois.admin() ? 'tw-justify-between' : 'tw-justify-end'},[_c('a-range-picker',{staticStyle:{"width":"200px"},attrs:{"allowClear":true,"format":"DD-MM-YYYY","valueFormat":"DD-MM-YYYY"},on:{"change":function($event){return _vm.fetchData()}},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_c('div',{staticClass:"tw-space-x-2"},[_c('a-select',{staticStyle:{"width":"220px"},attrs:{"options":_vm.statusOpt,"showSearch":"","filterOption":function (input, option) { return option.componentOptions.children[0].text
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0; }},on:{"change":function($event){return _vm.fetchData()}},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_c('a-tooltip',{attrs:{"title":_vm.$t('reDelivery.search')}},[_c('a-input-search',{staticStyle:{"width":"200px"},attrs:{"placeholder":_vm.$t('reDelivery.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]),_c('div',{staticClass:"tw-overflow-x-scroll"},[_c('ts-table',{attrs:{"columns":_vm.columns,"records":_vm.resources,"loading":_vm.loading},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var record = ref.record;
return [_c('td',[_vm._v(_vm._s(record.transaction_date))]),_c('td',[_vm._v(_vm._s(record.status_changed_date))]),_c('td',[_vm._v(_vm._s(record.booking_number))]),_c('td',[_vm._v(_vm._s(record.delivery_number))]),_c('td',[_vm._v(_vm._s(record.shop_code))]),_c('td',[_vm._v(_vm._s(record.shop_name))]),_c('td',[_vm._v(_vm._s(record.driver_name))]),_c('td',[_vm._v(_vm._s(record.delivery_status))]),_c('td',[_vm._v(_vm._s(record.receiver_phone))]),_c('td',[_vm._v(_vm._s(record.receiver_location))]),_c('td',[_vm._v(_vm._s(record.delivery_status_remark))])]}}])})],1),_c('div',{staticClass:"d-flex justify-content-end tw-p-4"},[_c('ts-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }