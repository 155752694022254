<template>
    <div>
        <ts-page-title
            :title="$t('reDelivery.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('reDelivery.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div
                    class="tw-flex tw-items-center"
                    :class="
                        $whois.admin() ? 'tw-justify-between' : 'tw-justify-end'
                    "
                >
                    <a-range-picker
                        v-model="dateRange"
                        :allowClear="true"
                        format="DD-MM-YYYY"
                        valueFormat="DD-MM-YYYY"
                        style="width: 200px"
                        @change="fetchData()"
                    />
                    <div class="tw-space-x-2">
                        <a-select
                            v-model="status"
                            style="width: 220px"
                            :options="statusOpt"
                            showSearch
                            :filterOption="
                                (input, option) =>
                                    option.componentOptions.children[0].text
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                            "
                            @change="fetchData()"
                        >
                        </a-select>
                        <a-tooltip :title="$t('reDelivery.search')">
                            <a-input-search
                                v-model="search"
                                :placeholder="$t('reDelivery.search')"
                                style="width: 200px"
                            />
                        </a-tooltip>
                    </div>
                </div>
            </ts-panel-wrapper>
            <div class="tw-overflow-x-scroll">
                <ts-table
                    :columns="columns"
                    :records="resources"
                    :loading="loading"
                >
                    <template v-slot="{ record }">
                        <td>{{ record.transaction_date }}</td>
                        <td>{{ record.status_changed_date }}</td>
                        <td>{{ record.booking_number }}</td>
                        <td>{{ record.delivery_number }}</td>
                        <td>{{ record.shop_code }}</td>
                        <td>{{ record.shop_name }}</td>
                        <td>{{ record.driver_name }}</td>
                        <td>{{ record.delivery_status }}</td>
                        <td>{{ record.receiver_phone }}</td>
                        <td>{{ record.receiver_location }}</td>
                        <td>{{ record.delivery_status_remark }}</td>
                    </template>
                </ts-table>
            </div>
            <div class="d-flex justify-content-end tw-p-4">
                <ts-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-pagination>
            </div>
        </ts-panel>
    </div>
</template>
<script>
import { debounce } from "lodash";
import { mapState } from "vuex";
export default {
    name: "re-delivery",
    data() {
        return {
            loading: false
        };
    },
    computed: {
        ...mapState("agency/reDelivery", ["resources", "pagination"]),
        search: {
            get() {
                return this.$store.state.agency.reDelivery.search;
            },
            set(newValue) {
                this.$store.commit("agency/reDelivery/SET_SEARCH", newValue);
                this.$store.commit("agency/reDelivery/RESET_CURRENT_PAGE");
            }
        },
        columns() {
            return [
                { name: this.$t("reDelivery.transactionDate") , sortKey: 'transaction_date'},
                { name: this.$t("reDelivery.changeStatusDate") , sortKey: 'status_changed_date'},
                { name: this.$t("reDelivery.bookingNumber") , sortKey: 'booking_number'},
                { name: this.$t("reDelivery.transactionNumber") , sortKey: 'delivery_number'},
                { name: this.$t("reDelivery.shopCode") , sortKey: 'shop_code'},
                { name: this.$t("reDelivery.shopName") , sortKey: 'shop_name'},
                { name: this.$t("reDelivery.driverName") , sortKey: 'driver_name'},
                { name: this.$t("reDelivery.status") , sortKey: 'delivery_status'},
                { name: this.$t("reDelivery.receiverPhone") , sortKey: 'receiver_phone'},
                { name: this.$t("reDelivery.receiverLocation") , sortKey: 'receiver_location'},
                { name: this.$t("reDelivery.remark"), sortKey: 'delivery_status_remark' }
            ];
        },
        dateRange: {
            get() {
                return this.$store.state.agency.reDelivery.dateRange;
            },
            set(newValue) {
                this.$store.commit(
                    "agency/reDelivery/SET_DATE_RANGE",
                    newValue
                );
            }
        },
        statusOpt() {
            return [
                { value: "", label: "All Status" },
                { value: 3, label: "Re-Delivery" },
                { value: 8, label: "Pending" }
            ];
        },
        status: {
            get() {
                return this.$store.state.agency.reDelivery.status;
            },
            set(newValue) {
                this.$store.commit("agency/reDelivery/SET_STATUS", newValue);
            }
        }
    },
    methods: {
        addNew() {
            this.$router.push({ name: "re-delivery-create" });
        },
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("agency/reDelivery/fetch", { ...attributes })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$notify({ type: "error", text: error.message });
                });
        }
    },
    watch: {
        search: debounce(function() {
            this.fetchData();
        }, 500)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("agency/agencyProfile/RESET_STATE");
        next();
    }
};
</script>
